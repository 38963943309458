@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.loader {
  -webkit-animation: rotate 1s infinite;
          animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  margin: auto auto;
  margin-top: 30%;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  -webkit-animation: ball1 1s infinite;
          animation: ball1 1s infinite;
  background-color: #3E270D;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.loader:after {
  -webkit-animation: ball2 1s infinite;
          animation: ball2 1s infinite;
  background-color: #2D717F;
  box-shadow: 30px 0 0 #B3E6F2;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@-webkit-keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@-webkit-keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #B3E6F2;
  }

  50% {
    box-shadow: 0 0 0 #B3E6F2;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #B3E6F2;
    margin-top: 0;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #B3E6F2;
  }

  50% {
    box-shadow: 0 0 0 #B3E6F2;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #B3E6F2;
    margin-top: 0;
  }
}
.navbar-nbs {
  background: var(--primary-color1);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container-nbs {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  height: 60px;
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid var(--primary-color4);
}

.nav-links {
  color: var(--primary-color2);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

a:hover {
  color: var(--primary-color2);
  opacity: 0.9;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-bottom: 0;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: var(--primary-color2);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 15%);
            transform: translate(25%, 15%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .menu-icon>* {
    vertical-align: baseline;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}
:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  font-family: "Roboto Mono", monospace;
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

.green {
  background: #25ce4a;
  color: #fff;
  border: none;
}

.green:hover {
  background-color: #242424;
}
:root {
  --primary-color1: #23212c;
  --primary-color2: #eef9fc;
  --primary-color3: #fcb43a;
  --primary-color4: #ffffff;
  --primary-background: linear-gradient(168.18deg, #fff 5.77%, #b3e6f2 91.84%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
}

/* Fonts */

.title {
  font-size: 45px;
  font-family: "Roboto", sans-serif;
  color: #09424f;
  font-weight: 900;
}

.subtitle {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.text {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
}

/* Components */

.card {
  border: none;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.hide {
  opacity: 0;
}

.submit-btn {
  background-color: #23212c;
  background-color: var(--primary-color1);
  color: white;
  border: white 1px solid;
}

.submit-btn:hover {
  opacity: 0.9;
  background-color: #23212c;
  background-color: var(--primary-color1);
}

.email-verify,
.loading,
.home,
.dashboard,
.team,
.support {
  height: 100%;
  min-width: 100%;
  background-image: linear-gradient(168.18deg, #fff 5.77%, #b3e6f2 91.84%);
  background-image: var(--primary-background);
}

.gradient {
  background: linear-gradient(168.18deg, #fff 5.77%, #b3e6f2 91.84%);
}

.gradient-muted {
  background: linear-gradient(168.18deg, #d9f2f8 5.77%, #c6ecf5 91.84%);
}

.chakra-input {
  background-color: white !important;
  font-family: 'Roboto Mono', monospace;
}

.chakra-heading {
  font-family: 'Roboto', sans-serif !important;
}

.chakra-text {
  font-family: 'Roboto Mono', monospace;
}

.chakra-button,
.chakra-input__right-addon {
  font-family: 'Roboto', sans-serif !important;
  color: #fff !important;
  font-weight: 400 !important;
  background: #09424f !important;
  border-color: #09424f !important;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;
}

.btn-check:focus+.chakra-button,
.chakra-button:focus,
.chakra-input__right-addon:focus {
  background-color: #126d84 !important;
  border-color: #126d84 !important;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%) !important;
}

.chakra-button:hover,
.chakra-input__right-addon:hover {
  background-color: #126d84 !important;
  border-color: #126d84 !important;
}
