@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

:root {
  --primary-color1: #23212c;
  --primary-color2: #eef9fc;
  --primary-color3: #fcb43a;
  --primary-color4: #ffffff;
  --primary-background: linear-gradient(168.18deg, #fff 5.77%, #b3e6f2 91.84%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
}

/* Fonts */

.title {
  font-size: 45px;
  font-family: "Roboto", sans-serif;
  color: #09424f;
  font-weight: 900;
}

.subtitle {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.text {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
}

/* Components */

.card {
  border: none;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
}

.hide {
  opacity: 0;
}

.submit-btn {
  background-color: var(--primary-color1);
  color: white;
  border: white 1px solid;
}

.submit-btn:hover {
  opacity: 0.9;
  background-color: var(--primary-color1);
}

.email-verify,
.loading,
.home,
.dashboard,
.team,
.support {
  height: 100%;
  min-width: 100%;
  background-image: var(--primary-background);
}

.gradient {
  background: linear-gradient(168.18deg, #fff 5.77%, #b3e6f2 91.84%);
}

.gradient-muted {
  background: linear-gradient(168.18deg, #d9f2f8 5.77%, #c6ecf5 91.84%);
}

.chakra-input {
  background-color: white !important;
  font-family: 'Roboto Mono', monospace;
}

.chakra-heading {
  font-family: 'Roboto', sans-serif !important;
}

.chakra-text {
  font-family: 'Roboto Mono', monospace;
}

.chakra-button,
.chakra-input__right-addon {
  font-family: 'Roboto', sans-serif !important;
  color: #fff !important;
  font-weight: 400 !important;
  background: #09424f !important;
  border-color: #09424f !important;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2) !important;
}

.btn-check:focus+.chakra-button,
.chakra-button:focus,
.chakra-input__right-addon:focus {
  background-color: #126d84 !important;
  border-color: #126d84 !important;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%) !important;
}

.chakra-button:hover,
.chakra-input__right-addon:hover {
  background-color: #126d84 !important;
  border-color: #126d84 !important;
}